<ng-container>
    <mat-drawer-container>
        <mat-drawer-content>
            <section class="main_section">
                <div class="top-navbar">
                    <app-navbar></app-navbar>
                </div>
                <div class="body-section">
                    <router-outlet></router-outlet>
                </div>
                <div class="bottom-footer" *ngIf="!currentPageUrl?.includes('terms-conditions') && !currentPageUrl?.includes('privacy-policy')">
                    <app-footer></app-footer>
                </div>    
            </section>
        </mat-drawer-content>
    </mat-drawer-container>
</ng-container>